<template>
  <div class="home text-center bg-gray-100 py-10">
    <h2 class="text-2xl font-semibold mb-2">Welkom bij</h2>
    <h1 class="text-4xl font-bold mb-4">DeLokatie</h1>
    <img src="../assets/images/welkom.jpg" class="mx-auto rounded-lg shadow-lg mb-1" alt="Werkplek De Lokatie">
    <p class="font-semibold mb-8">Keramiek & Kunstatelier in <span class="text-blue-600">Zeewolde</span></p>
    <p class="font-semibold">Kunstenares <router-link :to="'/over-annemiek'" class="text-blue-600
        hover:underline">Annemiek Eradus</router-link></p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
});
</script>
