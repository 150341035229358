<template>
  <div class="not-found text-center bg-gray-100 py-10">
    <h1 class="text-2xl font-bold mb-4">Pagina niet gevonden!</h1>
    <p class="mb-2">Klik <router-link :to="'/'" class="text-blue-600
        hover:underline">hier</router-link>
      om terug te gaan naar de home pagina.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotFoundView',
});
</script>
