<template>
	<nav class="relative px-4 py-4 flex justify-between items-center bg-white">
		<a class="text-3xl font-bold leading-none" href="#" aria-label="Home pagina van De Lokatie">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100">
				<!-- PNG Image -->
				<image href="../assets/images/logo.png" x="0" y="0" width="100" height="100" />
			</svg>
		</a>
		<div class="lg:hidden">
			<button class="navbar-burger flex items-center text-blue-600 p-3">
				<svg class="block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
					<title>Mobile menu</title>
					<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
				</svg>
			</button>
		</div>
		<ul
			class="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">
			<li>
				<router-link to="/" class="text-sm text-blue-600 font-bold hover:text-gray-500">Home</router-link>
			</li>
			<li class="text-gray-300">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" class="w-4 h-4 current-fill"
					viewBox="0 0 24 24">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
						d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
				</svg>
			</li>
			<li>
				<router-link to="/over-annemiek" class="text-sm text-blue-600 font-bold hover:text-gray-500">Over
					Annemiek</router-link>
			</li>
			<li class="text-gray-300">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" class="w-4 h-4 current-fill"
					viewBox="0 0 24 24">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
						d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
				</svg>
			</li>
			<li>
				<router-link to="/atelier"
					class="text-sm text-blue-600 font-bold hover:text-gray-500">Atelier</router-link>
			</li>
			<li class="text-gray-300">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" class="w-4 h-4 current-fill"
					viewBox="0 0 24 24">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
						d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
				</svg>
			</li>
			<li>
				<router-link to="/contact"
					class="text-sm text-blue-600 font-bold hover:text-gray-500">Contact</router-link>
			</li>
		</ul>
	</nav>
	<div class="navbar-menu relative z-50 hidden">
		<div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
		<nav
			class="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
			<div class="flex items-center mb-8">
				<span class="mr-auto text-3xl font-bold leading-none" href="#">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="h-12" alt="logo">
						<!-- PNG Image -->
						<image href="../assets/images/logo.png" x="0" y="0" width="100" height="100" />
					</svg>
				</span>
				<button class="navbar-close">
					<svg class="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
						xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
						</path>
					</svg>
				</button>
			</div>
			<div>
				<ul>
					<li class="mb-1">
						<router-link to="/">Home</router-link>
					</li>
					<li class="mb-1">
						<router-link to="/over-annemiek">Over Annemiek</router-link>
					</li>
					<li class="mb-1">
						<router-link to="/atelier">Atelier</router-link>
					</li>
					<li class="mb-1">
						<router-link to="/contact">Contact</router-link>
					</li>
				</ul>
			</div>
			<div class="mt-auto">
			</div>
		</nav>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'NavBar',
});

// Burger menus
document.addEventListener('DOMContentLoaded', function () {
    // open
    const burger = document.querySelectorAll('.navbar-burger');
    const menu = document.querySelectorAll('.navbar-menu');

    if (burger.length && menu.length) {
        for (var i = 0; i < burger.length; i++) {
            burger[i].addEventListener('click', function () {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    // close
    const close = document.querySelectorAll('.navbar-close');
    const backdrop = document.querySelectorAll('.navbar-backdrop');

    if (close.length) {
        for (var x = 0; x < close.length; x++) {
            close[x].addEventListener('click', function () {
                for (var y = 0; y < menu.length; y++) {
                    menu[y].classList.toggle('hidden');
                }
            });
        }
    }

    if (backdrop.length) {
        for (var p = 0; p < backdrop.length; p++) {
            backdrop[p].addEventListener('click', function () {
                for (var q = 0; q < menu.length; q++) {
                    menu[q].classList.toggle('hidden');
                }
            });
        }
    }
});
</script>

<style scoped>
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #066692;
}

nav a.router-link-exact-active {
  color: #96028e;
}
</style>